.admin_box {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    width: 100%;
    /* flex: 1; */
}

.admin_box .admin_box_top {
    display: flex;
    justify-content: space-between;
}

.admin_box .date_div {
    display: flex;
}

.admin_box .date_div button {
    background-color: #004EA2;
    color: #fff;
    font-weight: 600;
    padding: 3px 7px;
    border-radius: 4px;
    margin-left: 5px;
}

.admin_box_top .manage_datepicker {
    border: 1px solid #ccc;
    /* position: relative; */
    padding: 0 3px;
    border-radius: 5px;
}

.admin_box .admin_develop {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    z-index: 1;
    bottom: 0;
    right: 0;
    font-weight: 600;

    background: #ffffff83;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
}


.admin_box .admin_box_middle {
    margin-top: 10px;
}

.admin_box .admin_box_middle ul {
    display: flex;
}

.admin_box .admin_box_middle ul li {
    flex: 1;
    margin-right: 10px;
    border-radius: 10px;
}

.admin_box .admin_box_middle ul li:first-child {
    background: rgb(0, 78, 162);
    background: linear-gradient(270deg, rgba(0, 78, 162, 1) 39%, rgba(108, 169, 235, 1) 100%);
}

.admin_box .admin_box_middle ul li:nth-child(2) {
    background: rgb(0, 168, 67);
    background: linear-gradient(270deg, rgba(0, 168, 67, 1) 39%, rgba(59, 214, 121, 1) 100%);
}

.admin_box .admin_box_middle ul li:last-child {
    background: rgb(89, 0, 201);
    background: linear-gradient(270deg, rgba(89, 0, 201, 1) 39%, rgba(165, 111, 232, 1) 100%);
    margin-right: 0;
}

.admin_box .admin_box_middle ul li a {
    padding: 14px 10px;
    color: #fff;
    font-weight: 700;
    display: block;
}

.admin_box_middle .box_middle_inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.admin_box_middle .box_middle_inner div:first-child {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 700;
}

.admin_box_middle .box_middle_inner p {
    font-size: 18px;
}

.admin_box .admin_box_bottom {
    margin-top: 10px;
}

.admin_box .admin_box_bottom ul {
    display: flex;
}

.admin_box .admin_box_bottom ul li {
    flex: 1;
}

.admin_box .admin_box_bottom ul li:first-child {
    margin-right: 10px;
}

.admin_box .admin_box_bottom ul li a {
    padding: 14px 10px;
    display: block;
    text-align: center;
    border-radius: 7px;
    border: 1px solid #004EA2;
    color: #004EA2;
}

.admin_box_bottom div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.admin_box_bottom div span {
    margin-left: 10px;
}