.manage_table_box {
    padding: 15px;
}

.manage_table_box.manage_table_box_div {
    padding: 0;
}

.manage_table_box .manage_search_div {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
}

.manage_table_box .manage_search_div div>span:first-child {
    font-weight: 700;
    margin-right: 5px;
}

.manage_table_box .manage_search_div .manage_search_excel {
    border-radius: 5px;
    border: 1px solid #004EA2;
    padding: 5px 20px;
    color: #004EA2;
}

.manage_table,
.info_table {
    width: 100%;
    height: auto;

}

.manage_table .manage_thead,
.info_table .info_thead {
    background-color: #F1F1F1;

}

.manage_table .manage_thead tr th {
    padding: 8px 0;
}

.info_table .info_thead tr th {
    padding: 4px 0;

}

.info_table .info_thead tr th {
    font-size: 14px;
}

.manage_table .manage_tbody tr {
    border-bottom: 1px solid #ccc;
}

.manage_table .manage_tbody tr td {
    padding: 8px 0;
    text-align: center;
}

.info_table .info_tbody tr td {
    padding: 8px 0;
    text-align: center;
}

.manage_table_bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
}