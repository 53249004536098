@import "default.css";


#root {
  /* height: 100%; */
  /* position: relative;
  height: 100%;
  background-color: #F1F1F1 */
  background-color: #F1F1F1;
}


/* @import "font.css"; */
/* #container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #F1F1F1
} */

.common-wrap {
  width: 100%;
  height: 100%;

}



.slick-slider .slick-track {
  margin: 0 0;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  height: 100%;

  /* background-color: red; */
}

.slick-initialized .slick-slide>div {
  height: 100%;
}

.swiper-button-disabled {
  display: none !important;
}

.paging_area>ul {
  text-align: center;
  margin-top: 10px;
}

.paging_area>ul>li {
  display: inline-block;
  line-height: 21px;
  text-align: center;
  margin: 4.5px 10px;
  vertical-align: bottom;
}

.paging_area>ul>li.active {
  /* font-size: 20px; */
  font-weight: bold;
}

.paging_area>ul>li.active>a {
  color: blue;
}

.my_write_qna {
  width: 100%;
}

.my_write_qna .pagination {
  display: flex;
  /* width: 100%; */
  padding: 0;
  justify-content: center;
  margin: 0;
}

.my_write_qna .pagination li {
  align-items: center;
  display: inline-block;
  display: flex;
  font-size: 13px;
  height: 23px;
  justify-content: center;
  margin: 10px 10px;
  width: 23px;
  cursor: pointer;
  border: none;
}

.my_write_qna .pagination li:first-child,
.my_write_qna .pagination li:last-child {
  display: none;
}

.my_write_qna .pagination li:nth-child(2),
.my_write_qna .pagination li:nth-last-child(2) {
  background-color: #fff;
  border: none;
  border-radius: 0;
  font-size: 2rem;
  height: 30px;
  width: 30px;
  margin: 2px 5px 0;
}

.my_write_qna .pagination li a {
  color: black;
  text-decoration: none;
}

.my_write_qna .pagination li.active a {
  color: #fff;
}

.my_write_qna .pagination li.active {
  background-color: #337ab7;
  border: 1px solid #337ab7;
  border-radius: 4px;
}

.my_write_qna .pagination li a.active,
.my_write_qna .pagination li a:hover {
  color: black;
}

/* .main_section2_wrap > .slick-list {
  height: 400px;
}
.main_section2_wrap > .slick-list .slick-item-div {
  padding: 0 10px;
  background-color: red;
}

.main_section2_wrap > .slick-list .slick-item-link {
  cursor: pointer;

  width: 100%;
  height: 100%;
}
.main_section2_wrap > .slick-list .slick-item-imgbox {

  margin: 10px 0;
  background-color: red;
 
}
.main_section2_wrap > .slick-list .slick-item-img {
  width: 100%;
  height: 100%;


}
.main_section2_wrap > .slick-list .slick-item-contbox {
  height: 120px;
  padding: 20px 10px;
  text-align: center;
}
.main_section2_wrap > .slick-list .slick-item-contbox > button {
  margin-top: 30px;
  width: 140px;
  height: auto;
  border: 1px solid #c8d4c6;
  background-color: #d4d4d4;
} */
/* .slick-prev {
  width: 40px;
  height: 40px;
  left: -60px;
  color: black;
  line-height: 40px;

  font-weight: 600;
  font-size: 20px;

} */
.main_admin_user {
  position: fixed;
  right: 0px;

  z-index: 900;
  top: 50%;
  margin-top: -130px;
  width: 50px;
  height: 260px;
  padding: 20px;
  background-color: #77b69a;

  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.main_admin_user a {
  font-weight: 600;
  color: #fff;
  width: 100%;
  height: 100%;
  display: block;
}

.mobile_fix_wrap {
  display: none;
}

@media all and (max-width: 1200px) {}

@media all and (max-width: 1100px) {
  .common-inner-wrap {
    max-width: 100%;
    margin: 0 auto;
    /* padding: 0 40px; */
  }

  .mobile_fix_wrap {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    right: 0;
    height: 65px;
    background: #fff;
    padding: 10px 20px;
    z-index: 997;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
  }

  .mobile_fix_ul {
    width: 100%;
    margin: 0 auto;
    display: flex;
  }

  .mobile_fix_ul li {
    flex: 1;
    font-size: 12px;
    padding: 0 20px;
  }

  /* .mobile_fix_ul li:nth-child(3) .fix_li_imgbox {
    width: 50px;
    height: 50px;
  } */
  .mobile_fix_ul li a {
    /* background-color: red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .mobile_fix_ul .fix_li_imgbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
  }

  .mobile_fix_ul .fix_li_imgbox .fix_li_img {}
}

@media all and (max-width: 760px) {
  .mobile_fix_ul li {
    padding: 0;
  }

  .mobile_fix_ul .fix_li_imgbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }
}

/* 로고 */
/* .group {
  position: relative;
}
.logo {
  position: absolute;
  top: 16px;
  left: 60px;
  height: 48px;
  z-index: 50;
}
.logo .logo_anchor {
  position: relative;
  height: 48px;
  z-index: 10;
}

@media all and (max-width: 1000px) {
  .logo {
    left: 0;
    height: 30px;
    z-index: 10;
  }
  .logo .logo_anchor {
    height: 30px;
  }
} */

.alert_div_back_back {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(177, 177, 177, 0.244);
  z-index: 998;
}

.alert_div_back {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(177, 177, 177, 0.244); */
  /* background-color: fff; */
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeInDown 0.4s;
}

.alert_div_back.admin_back {
  position: static;
  animation: none;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

div:where(.swal2-icon) {
  margin: 2.5em auto .6em !important;
}

.swal2-popup {
  width: 320px !important;
  min-height: 120px !important;
  /* padding: 20px !important; */
}

.swal2-title {
  /* padding: 0 !important; */
  font-size: 22px !important;
  line-height: 26px;
  /* color:#1b47b9; */
}

/* .swal2-html-container {
  font-size: 16px !important;
  margin: 1em 1.2em 0.3em;
}

.swal2-html-container p {
  font-size: 12px !important;
}

.swal2-styled.swal2-confirm {
  font-size: 16px !important;
  flex: 1 !important;
  height: 30px !important;
  background-color: #00c73c !important;
  margin: 0 auto !important;
  font-weight: 700 !important;
  color: #fff !important;
  border-radius: 4px !important;
  line-height: 0 !important;
}

.swal2-styled.swal2-cancel {
  font-size: 16px !important;
  flex: 1 !important;
  height: 30px !important;
  background-color: #6e7881 !important;
  margin: 0 auto !important;
  font-weight: 700 !important;
  color: #fff !important;
  border-radius: 4px !important;
  line-height: 0 !important;
  margin-left: 4px !important;
} */

/* .swal2-input {
  margin: 8px auto !important;
}

.swal2-actions {
  width: 100% !important;
} */

.alert_div_back .alert_div_wrap {
  width: 320px;
  min-height: 120px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.alert_div_back .alert_div_wrap .alert_div_inner {
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  min-height: 120px;
}

.alert_div_back .alert_div_wrap .alert_div_content {
  width: 100%;
}

.alert_div_back .alert_div_wrap .btn_alert {
  width: 80%;
  height: 30px;
  background-color: #00c73c;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
}

.alert_div_back .alert_div_wrap2 {
  width: 380px;
  min-height: 120px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.alert_div_back .alert_div_wrap2 .alert_div_inner {
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  min-height: 120px;
}

.alert_div_back .alert_div_wrap2 .alert_div_title {
  /* font-size: 16px; */
  font-weight: 600;
  margin-bottom: 5px;
}

.alert_div_back .alert_div_wrap2 .alert_div_content {
  width: 100%;
}

.alert_div_back .alert_div_wrap2 .alert_div_content>div {
  margin-bottom: 10px;
}

.alert_div_back .alert_div_wrap2 .alert_div_content>div:first-child {
  font-weight: 500;
}

.alert_div_back .alert_div_wrap2 .alert_div_content>div:nth-child(2) {
  /* border: 1px solid #c8c9ca;
  border-radius: 5px; */
  /* margin-bottom: 20px; */
  width: 100%;
  font-size: 14px;
}

.alert_div_back .alert_div_wrap2 .alert_div_content>div:nth-child(2) ol {
  width: 100%;
  list-style-type: decimal;
  padding-inline-start: 14px;
}

.alert_div_back .alert_div_wrap2 .alert_div_content>div:nth-child(3) {
  border: 1px solid #c8c9ca;
  border-radius: 5px;

  padding: 5px;
  /* margin-bottom: 20px; */
}

.alert_div_back .alert_div_wrap2 .alert_div_content>div:nth-child(3) ol {
  width: 100%;
  list-style-type: decimal;
  padding-inline-start: 14px;
  font-size: 14px;
}

.alert_div_back .alert_div_wrap2 .alert_div_content>div:nth-child(3) span {
  font-size: 14px;
  text-indent: 14px;
}

.alert_div_back .alert_div_wrap2 .alert_div_content p {
  margin-bottom: 20px;
  /* font-size: 14px; */
  color: #00c73c;
  font-weight: 600;
  text-align: center;
}

.alert_div_back .alert_div_wrap2 .alert_div_content ul {
  width: 100%;

  /* text-align: left; */
}

.alert_div_back .alert_div_wrap2 .btn_box {
  display: flex;
}

.alert_div_back .alert_div_wrap2 .btn_alert {
  flex: 1;
  height: 30px;
  background-color: #00c73c;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  margin-right: 3px;
}

.alert_div_back .alert_div_wrap2 .btn_alert_cancel {
  flex: 1;
  height: 30px;
  background-color: #6e7881;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  margin-left: 3px;
}

.alert_div_back .alert_div_wrap3 {
  width: 420px;
  min-height: 120px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.alert_div_back .alert_div_wrap3 .alert_div_inner {
  display: flex;
  padding: 20px;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  min-height: 120px;
}

.alert_div_back .alert_div_wrap3 .alert_div_title {
  /* font-size: 16px; */
  font-weight: 600;
  margin-bottom: 20px;
}

.alert_div_back .alert_div_wrap3 .alert_div_content {
  width: 100%;
}

.alert_div_back .alert_div_wrap3 .alert_div_content>div {
  margin-bottom: 10px;
}

.alert_div_back .alert_div_wrap3 .alert_div_content>div:first-child {
  font-weight: 500;
}

.alert_div_back .alert_div_wrap3 .alert_div_content>div:nth-child(2) {
  border: 1px solid #c8c9ca;
  border-radius: 5px;
  /* margin-bottom: 20px; */
}

.alert_div_back .alert_div_wrap3 .alert_div_content p {
  margin-bottom: 10px;
  /* font-size: 14px; */
  color: #00c73c;
  font-weight: 600;
}

.alert_div_back .alert_div_wrap3 .alert_div_content ul {
  width: 100%;

  /* text-align: left; */
}

.alert_div_back .alert_div_wrap3 .btn_alert {
  height: 30px;
  width: 80%;
  background-color: #00c73c;
  margin: 0 auto;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  margin-top: 5px;
  /* margin-right: 3px; */
}

.modal-background {
  display: none;
}

.modal-background.view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  display: block;
}

.modal-background-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.table_style thead tr:first-child>th:first-child span {
  display: none;
}

/* 컬러 */
.em_red {
  color: #e74c3c;
}

.em_blue {
  color: #3498db;
}

.em_green {
  color: #0e9e5d;
}

.em_black {
  color: #333;
}

.none_view {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.none_view p {
  width: 100%;
  height: 30px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 말줄임 적용 */
}

.two_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.em_line {
  -webkit-text-decoration-line: underline;
  /* Safari */
  text-decoration-line: underline;
}

.text_right {
  text-align: right;
}

.propagate-background {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
}

.propagate-background>div {}

.invest_custom_class_name {
  /* background-color: red; */
  position: absolute;
  bottom: 20px;

  width: 100%;
  display: flex !important;
  justify-content: center;
  /* margin-top: 30px; */
}

.invest_custom_class_name>li {
  width: 7px;
  height: 7px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;

  margin-right: 5px;
  opacity: 0.5;
  cursor: pointer;
}

.invest_custom_class_name>li.slick-active {
  opacity: 1;
}


.customoverlay {
  position: relative;
  bottom: 45px;
  border: 1px solid #ccc;
  border-radius: 15px;
  /* border-bottom: 2px solid #ddd; */
  float: left;
}

.customoverlay .title {
  display: block;
  text-decoration: none;
  color: #000;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;

  text-align: center;
  background: #fff;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
}

.customoverlay:nth-of-type(n) {
  border: 0;
  box-shadow: 0px 2px 4px #888;
}

.customoverlay::after {
  display: block;
  content: '';
  position: absolute;
  margin-left: -12px;
  left: 50%;
  /* bottom: -12px; */
  width: 22px;
  height: 12px;
  background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png');
}


.info_title {
  display: block;
  position: absolute;
  bottom: 42px;
  border: 1px solid #ccc;
  left: 0;
  border-radius: 15px;
  transform: translateX(-50%);
  /* border-bottom: 2px solid #ddd; */
  float: left;
}

.info_title .title {
  display: block;
  text-decoration: none;
  color: #000;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;

  text-align: center;
  background: #fff;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
}

.info_title:nth-of-type(n) {
  border: 0;
  box-shadow: 0px 2px 4px #888;
}

.info_title::after {
  display: block;
  content: '';
  position: absolute;
  margin-left: -12px;
  left: 50%;
  width: 22px;
  height: 12px;
  background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png');
}

#map img {
  z-index: 150;
}