@charset "UTF-8";

html {
  /* font-size: 62.5%; */
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section {
  display: block;
}

.customoverlay {
  position: relative;
  bottom: 45px;
  border: 1px solid #ccc;
  border-radius: 15px;
  /* border-bottom: 2px solid #ddd; */
  float: left;
}

.customoverlay .title {
  display: block;
  text-decoration: none;
  color: #000;
  text-align: center;
  border-radius: 15px;
  overflow: hidden;

  text-align: center;
  background: #fff;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
}

.customoverlay:nth-of-type(n) {
  border: 0;
  box-shadow: 0px 2px 4px #888;
}

.customoverlay::after {
  display: block;
  content: '';
  position: absolute;
  margin-left: -12px;
  left: 50%;
  /* bottom: -12px; */
  width: 22px;
  height: 12px;
  background: url('https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png');
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

article,
aside,
audio,
blockquote,
/* body, */
canvas,
caption,
dd,
details,
div,
dl,
dt,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
iframe,
legend,
li,
main,
menu,
nav,
ol,
p,
pre,
section,
summary,
table,
tbody,
td,
template,
textarea,
tfoot,
th,
thead,
tr,
ul,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
  word-break: keep-all;
  /* background-color: beige; */
}

a,
em,
span {
  display: inline-block;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

b,
strong {
  font-weight: 600;
}

address,
cite,
dfn,
em,
i {
  font-style: normal;
}

img {
  border: none;
  vertical-align: middle;
}

audio,
img,
video {
  max-width: 100%;
  max-height: 100%;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

switch {
  display: none;
}

figcaption,
figure {
  margin: 0;
  padding: 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 90%;
}

sub,
sup {
  position: relative;
  font-size: 0.9em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

del {
  color: #aaa !important;
  text-decoration: line-through;
}

label {
  display: inline-block;
  /* cursor: pointer; */
}

iframe {
  display: block;
  max-width: 100%;
}

template {
  display: none;
}

summary {
  display: list-item;
  cursor: pointer;
}

output {
  display: inline-block;
}

ul,
ul>li {
  list-style: none;
}

ol {
  overflow: hidden;
  position: relative;
  counter-reset: number;
}

ol>li {
  counter-increment: number;
}

ol>li:before {
  content: counter(number, decimal) ".";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption {
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  background: 0 0;
  font-size: 0;
  color: transparent;
  line-height: 0;
  text-indent: 100%;
}

legend {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  background: 0 0;
  font-size: 0;
  color: transparent;
  line-height: 0;
}

fieldset {
  border: none;
  min-width: 0;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

hr {
  /* overflow: hidden;
  position: absolute;
  height: 0;
  width: 0; */
}

:after,
:before {
  display: none;
  content: "";
}

.clearfix {
  display: block;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.clear {
  clear: both;
}

.hide {
  display: none;
}

.skip {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  background: 0 0;
  font-size: 0 !important;
  color: transparent !important;
  line-height: 0;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding: 0;
  border: 1px solid;
  box-sizing: border-box;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  vertical-align: middle;
}

button,
input,
select {
  display: inline-block;
  outline: none;
}

button,
input {
  overflow: visible;
}

input::placeholder {
  color: #b5b5b5;
  font-size: 12px;
}

input[type="search"],
input[type="text"] {
  -webkit-appearance: none;
}

input[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="file"] {
  border: none;
}

input[type="file"]::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
  -moz-appearance: button;
  border: 1px solid #bbb;
  background: #e0e0e0;
  box-shadow: none;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
  border: none;
}

button,
select {
  text-transform: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  overflow: visible;
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
}

textarea {
  display: block;
  overflow: auto;
  resize: vertical;
}

input[disabled] {
  background-color: #ebebeb;
}

select:disabled {
  background-color: #ebebeb;
}

[hidden] {
  display: none !important;
}

@media screen and (max-width: 768px) {

  .skip,
  caption,
  legend {
    overflow: visible;
    font-size: 3px !important;
  }
}

a:active,
a:hover,
a:link,
a:visited {
  color: #444;
  text-decoration: none;
}

a:focus {
  /* text-decoration: underline; */
}

button,
input,
optgroup,
select,
textarea {
  border-width: 0;
}


html {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

body {
  /* overflow-x: hidden; */
  position: relative;
  background-color: #fff;
  color: #444;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.025em;
  font-weight: 400;
  font-family: "yoon", "NotoKr", "Malgun Gothic", "맑은 고딕", "Dotum", "돋움",
    "Gulim", "굴림", sans-serif;
  word-wrap: break-word;
  -webkit-text-size-adjust: none;
  width: 100%;
  height: 100%;
}