.hedaer_wrap {
    width: 100vw;
    height: 60px;
    background-color: #004EA2;
}

.header_box {
    padding: 0 20px;
    display: flex;
    height: 100%;
    align-items: center;
}

.header_box .header_logo {
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    /* flex: 0; */
    width: 185px;
}

.header_nav {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.header_nav ul {
    padding-left: 20px;

    display: flex;
}

.header_nav ul .header_a {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    padding: 0 10px;
    position: relative;
}

.header_nav ul .header_a.view::after {
    display: block;
    content: "";
    width: 100%;
    height: 2px;
    background: #fff;
    margin-top: 5px;
}

.header_side span {
    color: #fff;
    margin-right: 10px;
}

.header_side button {
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    ;
    border-radius: 4px;
    font-style: normal;
    height: 25px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    padding: 0 10px;
    color: white;
    background-color: transparent;
}