.login_container {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 10;
    overflow: hidden;
}

.login_container::before {
    display: block !important;
    content: "";
    background: url("./../assets/images/login_bg.jpg");
    background-size: cover;
    opacity: 0.4;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.login_wrap {
    display: block;
    position: relative;
    /* width: 1500px; */
    margin-right: auto;
    margin-left: auto;
}

.login_area_box {
    opacity: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 20px;
    width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgb(102 102 102 / 10%);
    border-radius: 20px;
    position: absolute;
    left: calc(50% - 200px);
    top: 25vh;
}

.login_area_box>img {
    width: 160px;
}

.login_area_title {
    font-weight: 900;
    font-size: 20px;
    margin: 0;
    color: #004EA2;
}

.login_inp_area input {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
    width: -webkit-fill-available;
    margin-bottom: 15px;
}

.login_inp_area button {
    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-style: normal;
    height: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 10px;
    color: white;
    width: -webkit-fill-available;
    background-color: #004EA2;
}