.footer_wrap {
    position: fixed;
    left: 0px;
    bottom: 0px;
    background-color: white;
    padding: 12px 20px;
    z-index: 300;
    width: calc(100vw - 24px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-top: 1px solid #CCCCCC;
    text-wrap: nowrap;
    overflow: hidden;
}

.footer_wrap > span {
    flex: 1;
}

.footer_wrap > img {
    display: inline-block;
    height: 28px;
    padding-right: 12px;
}