.main_container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    width: 100vw;
    min-height: calc(100vh - 60px);
}

.main_container > .main_left_div {
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 360px;
    height: calc(100vh - 60px);
    background: #FFFFFF;
    border-right: 1px solid #CCCCCC;
    z-index: 200; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 360px;
    height: calc(100vh - 113px) !important;
    background: #FFFFFF;
    border-right: 1px solid #CCCCCC;
    z-index: 200;
    overflow: auto;
    position: relative;
}

.main_left_div  hr {
    border: 2px solid #eee;
    width: 357px;
    margin: 0;
}

.main_left_div  .main_user_info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 360px;
    height: 56px;
    padding: 10px 20px;
    gap: 10px;
    box-sizing: border-box;
}

.main_left_div  .main_user_info > .user_info_area {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;
}

.main_left_div  .main_user_info > .user_info_area > .user_nickname {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #010101;
}

.main_left_div  .main_user_info > .user_info_area > .user_id {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #010101;
}

.main_left_div  .main_user_info > .user_info_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    cursor: pointer;
}

.main_left_div  .main_user_info > .user_info_view > span {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #004EA2;
}


.main_left_div  .main_call_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    min-height: 240px;
    gap: 10px;
    box-sizing: border-box;
}

.main_left_div  .main_call_info > .call_info_area {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #EEEEEE;
}

.main_left_div  .main_call_info > .call_info_area > .call_info_box {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
}

.main_left_div  .main_call_info > .call_info_area > .call_info_box > img {
    width: 14px;
    height: 14px;
}

.main_left_div  .main_call_info > .call_info_area > .call_info_box > .call_title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #010101;
}

.main_left_div  .main_call_info > .call_info_area > .call_info_view {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #004EA2;
}

.main_left_div  .main_call_info > .call_info_area > .call_info_box > .call_type_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 4px;
    overflow: hidden;
    width: 86px;
    height: 30px;
}

.main_left_div  .main_call_info > .call_info_area > .call_info_box > .call_type_box > div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 30px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
}

.main_left_div  .main_call_info > .call_info_list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow-y: scroll;
    flex: 1;
}

.main_left_div  .main_call_info > .call_info_list::-webkit-scrollbar {
    width: 12px;
}

.main_left_div  .main_call_info > .call_info_list > table thead {
    background-color: #F1F1F1 !important;
}

.main_left_div  .main_call_info > .call_info_list > table th {
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #333333;
}

.main_left_div  .main_call_info > .call_info_list > table td {
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #333333;
    padding: 1px;
}


.main_left_div  .main_call_info > .call_info_list .call_list_div {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 15px);
    height: 25px;
    padding-bottom: 4px;
    border-bottom: 1px solid #eee;
    margin-left: 15px;
    box-sizing: content-box;
}

.main_left_div  .main_call_info > .call_info_list .call_list_div > .call_list_name {
    line-height: 25px;
    font-size: 13px;
}

.main_left_div  .main_call_info > .call_info_list .call_list_div > .call_list_btn {
    background-color: rgb(0, 78, 162);
    color: white;
    border: 0;
    border-radius: 5px;
    font-size: 12px;
    height: 25px;
    cursor: pointer;
    padding: 1px 6px;
}

.main_left_div  .main_call_info_div {
    width: 360px;
    min-height: 100px;
}

.main_call_info_div > div {
    font-size: 13px;
    padding: 8px 25px;
    display: flex;
}

.main_call_info_div > div > div:first-child {
    width: 100px;
    font-weight: 600;
    color: rgb(144,144,144);
}

.main_call_info_div > div > div:last-child {
    font-weight: 600;
    color: #000;
}

.main_left_div  .main_call_handle_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    min-height: 300px;
    /* gap: 10px; */
    box-sizing: border-box;
}

.main_call_handle_div > .handle_list_div {
    width: 100%;
}

.main_call_handle_div > .handle_list_div > div {
    font-size: 13px;
    padding: 8px 25px;
    display: flex;
    align-items: center;
}

.main_call_handle_div > .handle_list_div > div > div:first-child {
    width: 100px;
    font-weight: 600;
    color: rgb(144,144,144);
}

.main_call_handle_div > .handle_list_div > div > .list_area {
    position: relative;
    width: 190px;
    border: 1px solid gray;
    border-radius: 3px;
    padding: 1px 3px; 
    height: 28px;
}

.main_call_handle_div > .handle_list_div > div > .list_area > input {
    width: 150px;
    border: 0;
    outline: none;
    height: 13px;
    padding: 8px 0px;
}

.main_call_handle_div > .handle_list_div > div > .list_area > ul {
    position: absolute;
    min-height: 30px;
    max-height: 150px;
    overflow: hidden;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
    left: 0;
    top: 26px;
    padding: 0;
    z-index: 30;
}

.main_call_handle_div > .handle_list_div > div > .list_area > ul  li {
    padding: 7px 15px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    list-style-type: none;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
}

.main_call_handle_div > .handle_list_div > div > .list_area > ul li > h2 {
    font-size: 13px;
    line-height: 15px;
}

.handle_list_div textarea {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
    font-size: 13px;
    padding:10px;
    resize:none;
    height: 150px;
}
.handle_list_div > .button_area {
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.handle_list_div .button_area .cancel_btn {
    border: 1px solid #004EA2;
    border-radius: 4px;
    color: #004EA2;
    font-weight: 600;
    background-color: white;
    width: 100px;
    height: 40px;
    cursor: pointer;
}

.handle_list_div .button_area .comp_btn {
    color:white;
    border-radius: 4px;
    border: 1px solid rgb(144,144,144);
    background-color: rgb(144,144,144);
    width: 200px;
    height: 40px;
    cursor: pointer;
}

.main_call_handle_div > .call_info_area {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    gap: 10px;
    box-sizing: border-box;
}

.main_call_handle_div > .call_info_area > .call_info_box {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-sizing: border-box;
}

.main_call_handle_div > .call_info_area > .call_info_box .call_title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #010101;
}


.main_left_div  .custom_scrollbars {
    position: absolute;
    width: 6px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
}

.custom_scrollbars > div {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.2);
    height: calc(100vh - 170px);
}


.main_right_div {
    flex: 1;
    height: calc(100vh - 60px);
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F1F1F1;
}

.main_right_div > .map_type_box {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 4px;
    overflow: hidden;
    width: 132px;
    height: 38px;
    z-index: 10;
}

.main_right_div > .map_type_box > div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 38px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
}

.main_right_div > .map_select_box {
    position: absolute;
    left: 162px;
    top: 20px;
    width: 300px;
    height: 38px;
    z-index: 10;
    padding: 0 10px;
    border: 1px solid #ccc;
    color: #000;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
}

.main_right_div > .map_operate {
    position: absolute;
    left: 40px;
    bottom: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    background-color: #004EA2;
    border-radius: 200px;
    z-index: 10;
    cursor: pointer;
    font-size: 40px;
    color: #fff;
}

.main_right_div > .map_call_btn {
    position: absolute;
    right: 40px;
    bottom: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    background-color: #004EA2;
    border-radius: 200px;
    z-index: 10;
    cursor: pointer;
}

.main_right_div > .map_call_btn > img {
    width: 76px;
    height: 76px;
}