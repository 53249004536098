.manage_wrap {
    flex: 1;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* height: 100%; */

}

.manage_wrap .manage_box {
    width: 1400px;
    /* min-height: 710px; */
    padding: 20px 0;

    /* background-color: antiquewhite; */
    /* background:  */
}

.manage_wrap .manage_box .manage_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.manage_wrap .manage_box .manage_top span {
    font-size: 20px;
    font-weight: 700;
}

.manage_wrap .manage_box .manage_top button {
    background: #004EA2;
    padding: 5px 10px;
    color: #fff;
}

.manage_wrap .manage_box .manage_middle {
    width: 100%;
    height: auto;
    background-color: #fff;

}


/* 검색 */
.manage_middle .mange_search_wrap {
    padding: 15px;
    border-bottom: 2px solid #F1F1F1;
}


.manage_search_box .manage_search_inner {
    width: 100%;
    display: flex;
}

.manage_search_box .manage_search_inner label {
    font-size: 14px;
    padding-right: 20px;
}

.manage_search_inner .manage_date {
    display: flex;
}

.manage_search_inner .manage_search {
    margin-left: 20px;
}

.manage_search_inner input {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 28px;
    font-size: 14px;
    text-indent: 4px;
}

.manage_search_inner .manage_datepicker {
    position: relative;
}

.manage_search_inner .manage_date .datepicker_ico {
    position: absolute;
    right: 10px;
}

.manage_search_box .manage_serach_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

}

.manage_search_box .manage_serach_button button {
    width: 100px;
    height: 34px;
    border: 1px solid #004EA2;
    /* background-color: #004EA2; */
    border-radius: 5px;
    color: #004EA2;
    font-weight: 600;
    font-size: 14px;

}

.manage_search_box .manage_serach_button button:last-child {
    background-color: #004EA2;
    color: #fff;
    margin-left: 10px;
}

.manage_middle .manage_select {
    padding: 0 10px;
}

.manage_middle .manage_select select {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 28px;
    font-size: 14px;
    text-indent: 4px;
    width: 120px;
}

/* 버스 정류장 관리 */
.stop_wrap {
    display: flex;
    min-height: calc(100vh - 60px);
    /* height: 100%; */
    /* background-color: red; */
    /* justify-content: center; */
    /* align-items: center; */
}

.stop_wrap .stop_box {
    flex: 1;
    display: flex;
    justify-content: center;
}

.stop_box .stop_content {
    width: 800px;
}

.stop_content .stop_content_top {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.stop_content .stop_content_top button {
    width: 95px;
    height: 28px;
    color: #fff;
    background-color: #ff0000;
    border-radius: 5px;
}

.stop_content .stop_inner {
    display: flex;
    align-items: center;
    /* padding: 20px 0; */

}

.stop_content .stop_inner>div {
    font-weight: 700;
}

.stop_info_wrap {
    width: 100%;
    height: auto;
    background-color: #fff;

}

.stop_info_wrap .stop_info_box {
    width: 100%;
    height: auto;
    padding: 20px;
    /* width: 1400px; */
    /* height: 100%; */
}

.stop_info_box .stop_info_table {
    width: 100%;
    border-top: 1px solid #F1F1F1;
}

.stop_info_table .stop_info_row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
    height: 40px;

}

.stop_info_table .stop_info_span {
    padding-left: 10px;
    background-color: #F1F1F1;
    display: block;
    height: 100%;
    width: 120px;
    line-height: 40px;


}

.stop_info_table .stop_info_input {
    padding-left: 10px;
}

.stop_info_table .stop_info_row input[type='text'] {
    border: 1px solid #ccc;
    width: 240px;
}

.stop_info_table .stop_info_row:nth-last-child(2) .stop_info_input,
.stop_info_table .stop_info_row:last-child .stop_info_input {
    display: flex;
    align-items: center;
}

.stop_info_table .stop_info_row:last-child input[type='text'] {
    width: 120px;
}

.stop_save {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.stop_save button {
    width: 120px;
    height: 34px;
    background: #004EA2;
    color: #fff;
    border-radius: 5px;
}

/* 버스 노선 관리 */
.line_wrap {
    display: flex;
    min-height: calc(100vh - 60px);
    /* height: 100%; */
    /* background-color: red; */
    /* justify-content: center; */
    /* align-items: center; */
}

.line_wrap .line_left_menu {
    flex: none;
    width: 240px;
    height: 100%;
    background: #fff;
}

.line_box {
    /* padding-top: 30px; */
    flex: 1;
    display: flex;
    justify-content: center;
    /* width: 1400px; */
}

.line_box .line_content {
    width: 1400px;
}

.line_content .line_content_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.line_content .line_content_top>div button {
    color: #fff;
    background-color: #004EA2;
    padding: 10px 24px;
    border-radius: 5px;
}

.line_content .line_content_top>div button:first-child {
    background-color: #ff0000;
    margin-right: 10px;
}

.line_inner {
    /* margin: 0 auto; */
    display: flex;
    align-items: center;
    padding: 20px 0;
}

.line_menu {
    display: flex;
    font-size: 20px;
}

.line_menu li a {
    padding: 10px 25px;
    text-align: center;
    font-weight: 700;

    /* background: red; */
}

.line_menu li {
    border-bottom: 3px solid #ccc;
}

.line_menu li.line_menu_li_color {
    border-bottom: 3px solid #004EA2;
}

.line_menu li.line_menu_li_color a {
    color: #004EA2;
}


/* 버스 노선 정보 */
.route_info_wrap {
    width: 1400px;
    height: auto;
    background-color: #fff;

}

.route_info_wrap .route_info_box {
    width: 100%;
    height: auto;
    padding: 20px;
    /* width: 1400px; */
    /* height: 100%; */
}

.route_info_box .route_info_top {
    display: flex;
    margin-bottom: 20px;
    border-top: 1px solid #F1F1F1;
    border-bottom: 1px solid #F1F1F1;
}

.route_info_top .route_info_title {
    background-color: #F1F1F1;
    padding: 20px;
    width: 140px;

}

.route_info_top .route_info_div {
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.route_info_top .route_info_div>input {
    border: 1px solid #ccc;
    height: 35px;
    width: 300px;
    margin-right: 20px;
}

.route_info_top .route_info_div>div {
    font-weight: 600;
    font-size: 14px;
}

.route_info_top button {
    background-color: #004EA2;
    color: #fff;
    margin-left: 20px;
    padding: 5px 20px;
    /* height: 40px; */
    border-radius: 5px;
}

.route_info_box .route_info_middle {
    /* display: flex; */
    margin-bottom: 20px;

}

.route_info_middle .route_info_title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.route_info_middle .route_info_title>button {
    background: #004EA2;
    color: #fff;
    padding: 5px 10px;
    margin-left: 10px;
    border-radius: 3px;
    font-size: 12px;
}

.route_info_middle textarea {
    resize: none;
    width: 500px;
    height: 200px;
    border: 1px solid black;
    outline: none;

}

.route_info_bottom .route_info_title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.route_info_bottom .route_info_title>span {
    font-weight: 700;

}

.route_info_bottom .route_info_title div button {
    background: #004EA2;
    color: #fff;
    padding: 8px 20px;
    margin-left: 10px;
    border-radius: 3px;
    font-size: 14px;
}


.route_map_modal {
    position: absolute;
    width: 500px;
    height: auto;
    background-color: #fff;
    /* border-radius: 5px; */
    border-radius: 10px;
    border: 3px solid #a7a7a7;
    top: 150px;
    left: 500px;
    z-index: 3;
    padding: 20px;
}

.route_map_modal .route_modal_inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.route_modal_inner .route_modal_title {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    /* border-bottom: 1px solid #a7a7a7; */
    flex: 0;
}

.route_modal_inner .route_modal_title span {
    font-size: 24px;
    font-weight: 600;
}


.line_modal_inner .route_modal_content {
    flex: 1;

}

.route_modal_content .route_modal_list {
    /* overflow-y: scroll; */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
}

.route_modal_list select {
    height: 40px;
    border-radius: 5px;
    width: 200px;
    border: 1px solid #a7a7a7;
}

.route_modal_content .route_modal_list p {
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #004EA2;
    font-weight: 600;
}

.route_modal_inner .route_modal_btn {
    /* margin-top: 40px; */
    width: 100%;
    height: 60px;
    /* flex: 0; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.route_modal_inner .route_modal_btn button {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background: #a7a7a7;
    color: #fff;
}

.route_modal_inner .route_modal_btn button:last-child {
    background: #004EA2;
    margin-left: 10px;
}

/* 버스 노선 경로*/
.line_map_wrap {
    position: relative;
    display: flex;
    height: 800px;
    /* background-color: red; */
}

.line_map_wrap .line_map {
    width: 800px;
    height: 800px;
    overflow: hidden;
    /* position: relative; */
}

.line_map_wrap .line_map_info {
    width: 600px;
    height: 800px;
    background-color: #fff;
    padding: 25px;
}

.line_map_info .line_info_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.line_info_top .line_info_title {
    font-weight: 600;
}

.line_info_menu .info_menu_ul {
    display: flex;
}

.line_info_menu .info_menu_ul li {
    border-radius: 5px;
    border: 1px solid #004EA2;
    padding: 5px 20px;
    color: #004EA2;
    margin: 0 5px;

}

.line_info_menu .info_menu_ul li:last-child {
    border: 0;
    background: #004EA2;

}

.line_info_menu .info_menu_ul li:last-child span {
    color: #fff;
}

.line_map_info .line_info_content {
    margin-top: 10px;
}

.line_info_content .line_info_inner {
    overflow: auto;
    scroll-snap-type: both mandatory;
    height: 680px;
    /* height: 68vh; */
}

.line_info_content .line_info_table {
    width: 100%;
    font-size: 12px;
}

.line_info_table .info_table_th th {
    padding: 10px 0;
    line-height: 14px;
    text-align: center;
}

.line_info_table .info_table_th tr {
    background-color: #F1F1F1;

}

.info_table_tbody .info_td_first>div>div {
    color: #004EA2;
    width: 25px;
    height: 25px;
    /* background-color: red; */
}

.info_table_tbody .info_td_first>div>div svg {
    width: 100%;
    height: 100%;
}

.info_table_tbody .info_td_essential {
    background: #004EA2;
    color: #fff;
    padding: 0 20px;
    border-radius: 5px;
}



.info_table_tbody .info_td_essential.essential_not {
    color: #004EA2;
    background: #fff;
    border: 1px solid #004EA2;

}

.info_table_tbody .info_td_remove {
    padding: 0 20px;
    border-radius: 5px;
    /* color: #004EA2; */
    background: #F1F1F1;
    border: 1px solid #004EA2;
}

.line_map_modal {
    position: absolute;
    width: 500px;
    height: 600px;
    background-color: #fff;
    border-radius: 5px;
    border: 3px solid #a7a7a7;
    top: 100px;
    left: 500px;
    z-index: 3;
    padding: 20px;
}

.line_map_modal .line_modal_inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.line_modal_inner .line_modal_title {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    border-bottom: 1px solid #a7a7a7;
    flex: 0;
}

.line_modal_inner .line_modal_title span {
    font-size: 20px;
    font-weight: 600;
}

.line_modal_inner .line_modal_search {
    padding: 10px 0;
    flex: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* justify-content: flex-end; */
}

.line_modal_search .line_search_box {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}


.line_search_box input {
    border: 1px solid #ccc;
    height: 45px;
    border-radius: 5px;
    flex: 1;
}

.line_search_box button {
    background-color: #004EA2;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    margin-left: 10px;
    flex: 0;
}

.line_modal_search .line_search_filter {
    width: 120px;
    height: 35px;
    border: 1px solid #ccc;
    position: relative;
    margin-top: 10px;
    border-radius: 5px;
}

.line_search_filter div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.line_search_filter .line_search_ul {
    background-color: #fff;
    text-align: center;
    position: absolute;
    bottom: -50px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: none;
}

.line_search_filter .line_search_ul.line_filter_view {
    display: block;
}

.line_modal_inner .line_modal_content {
    flex: 1;
    /* height: 300px; */
    overflow: hidden;
}

.line_modal_content .line_modal_list {
    overflow-y: scroll;
    height: 100%;
}

.line_modal_list .line_modal_item {
    width: 100%;
    height: 34px;
}

.line_modal_list .line_modal_item label {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
}

.line_modal_item .line_modal_check {
    font-weight: 500;
}

.line_modal_item .line_modal_check.line_check {
    font-weight: 700;
}

.line_modal_inner .line_modal_btn {
    margin-top: 40px;
    width: 100%;
    height: 60px;
    /* flex: 0; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.line_modal_inner .line_modal_btn button {
    width: 120px;
    height: 40px;
    border-radius: 5px;
    background: #a7a7a7;
    color: #fff;
}

.line_modal_inner .line_modal_btn button:last-child {
    background: #004EA2;
    margin-left: 10px;
}

/* .line_info_table .info_table_th ul {
    display: flex;
} */

.line_map_btn {
    position: absolute;
    z-index: 2;
    display: flex;
    width: 300px;
    height: 80px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
}

.line_map_btn .line_map_draw {
    width: 130px;
    height: 50px;
    background: #004EA2;
    border-radius: 10px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}

.line_map_btn .line_map_save {
    display: none;
    width: 130px;
    height: 50px;
    background: #004EA2;
    border-radius: 10px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
}


.race_detail_wrap {
    /* position: absolute;
    top: 0;
    left: 0; */
    /* bottom: 0; */
    /* z-index: 1; */
    width: 100%;
    height: 100%;
    background-color: #fff;
}


/* 버스 운수사 */




/* 1:1 문의 관리 */
.company_wrap {
    display: flex;
    min-height: calc(100vh - 60px);

    /* height: 100%; */
    /* background-color: red; */
    /* justify-content: center; */
    /* align-items: center; */
}

.company_wrap .company_box {
    flex: 1;
    display: flex;
    justify-content: center;
}

.company_box .company_content {
    width: 800px;
}

.company_content .company_content_top {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.company_content .company_content_top .cancel_btn {
    width: 95px;
    height: 28px;
    color: #fff;
    background-color: #ff0000;
    border-radius: 5px;
}

.company_content .company_content_top .save_btn {
    width: 95px;
    height: 28px;
    color: #fff;
    background-color: #004EA2;
    border-radius: 5px;
    margin-right: 10px;
}

.company_content .company_info_box2 button {
    width: 95px;
    height: 28px;
    color: #fff;
    background-color: #004EA2;
    border-radius: 5px;

}

.company_content .company_inner {
    display: flex;
    align-items: center;
    /* padding: 20px 0; */

}

.company_content .company_inner>div {
    font-weight: 700;
}


.company_info_wrap {
    width: 100%;
    height: auto;
    background-color: #fff;

}

.company_info_wrap .company_info_box {
    width: 100%;
    height: auto;
    padding: 20px;
}

.company_info_wrap .company_info_box2 {
    width: 100%;
    height: auto;
    padding: 20px;
}

.company_info_box .company_info_etc {
    display: flex;
    margin-bottom: 5px;
}

.company_info_box .company_info_etc p:first-child {

    font-weight: 700;

}

.company_info_box .company_info_etc p:last-child {
    margin-left: 10px;
}

.company_info_box .company_info_etc p:last-child span {
    padding: 0 4px;
}

.company_info_box .company_info_etc p:last-child span:first-child {
    position: relative;
}

.company_info_box .company_info_etc p:last-child span:first-child::after {
    position: absolute;
    background-color: #ccc;
    width: 2px;
    top: 5px;
    height: 15px;
    right: 0;
    /* top: 0; */
    display: block;
    content: "";
}

.company_info_box .company_info_table {
    width: 100%;
    border-top: 1px solid #F1F1F1;
}

.company_info_table .company_info_row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F1F1F1;
    height: 40px;

}

.company_info_table .company_info_span {
    padding-left: 10px;
    background-color: #F1F1F1;
    display: block;
    height: 100%;
    width: 180px;
    line-height: 40px;


}

.company_info_table .company_info_input {
    padding-left: 10px;
    flex: 1;
}

.company_info_table .company_info_input>input {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 28px;
    font-size: 14px;
    text-indent: 4px;
}

.company_info_table .company_info_input>button {
    width: 70px;
    height: 30px;
    background-color: #004EA2;
    /* background-color: #004EA2; */
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
}

.company_modal {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: #a7a7a72d;
}

.company_modal .company_modal_flex {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.company_modal_flex .company_modal_inner {
    width: 440px;
    padding: 20px 10px;
    /* min-height: 140px; */
    background-color: #fff;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.company_modal_inner .company_modal_content1 {
    display: flex;
    flex-direction: column;
    /* justify-content: center;/ */
    align-items: center;
    height: 100px;
}

.company_modal_content1 p {
    font-size: 20px;
    font-weight: 600;
}

.company_modal_content1 select {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 34px;
    width: 240px;
    font-size: 14px;
    text-indent: 4px;
    margin-top: 20px;
}

.company_modal_content1 input {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 34px;
    width: 240px;
    font-size: 14px;
    text-indent: 4px;
    margin-top: 20px;
}

.company_modal_inner .company_modal_content2 {
    display: flex;
    flex-direction: column;
    /* justify-content: center;/ */
    align-items: center;
    height: 160px;
}

.company_modal_content2 p {
    font-size: 20px;
    font-weight: 600;
}

.company_modal_content2 select {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 34px;
    width: 240px;
    font-size: 14px;
    text-indent: 4px;
    margin-top: 20px;
}

.company_modal_content2 input {
    border: 1px solid #ccc;
    border-radius: 3px;
    height: 34px;
    width: 240px;
    font-size: 14px;
    text-indent: 4px;
    margin-top: 20px;
}



.company_modal_inner .company_modal_btn {
    display: flex;
    justify-content: center;

}

.company_modal_btn>button {
    font-size: 14px;
    background-color: #004EA2;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
    padding: 5px 14px;
    width: 110px;

}

.company_modal_btn>button:first-child {
    margin-right: 10px;
    background-color: #ff0000;
}