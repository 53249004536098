.menu_wrap {
    background-color: #fff;
    /* display: flex; */
    width: 240px;
    min-height: calc(100vh - 60px);
}

.menu_box {
    width: 100%;
    height: 100%;
    border-right: 1px solid #ccc;
}

.menu_box ul li {
    height: 50px;
    border-bottom: 2px solid #ccc;
}

.menu_box ul li .meun_a {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 20px;
    /* background-color: red; */
    justify-content: space-between;
    align-items: center;
}

.menu_box ul li .meun_a.meun_a_color {
    color: #004EA2;
    font-weight: 700;
}