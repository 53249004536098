.moniter_wrap {
    flex: 1;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* height: 100%; */

}

.moniter_wrap .moniter_box {
    width: 1400px;
    /* min-height: 710px; */
    padding: 20px 0;

    /* background-color: antiquewhite; */
    /* background:  */
}

.moniter_wrap .moniter_box .moniter_top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.moniter_wrap .moniter_box .moniter_top>div {
    display: flex;
}

.moniter_wrap .moniter_box .moniter_top>div>ul {
    display: flex;
}

.moniter_wrap .moniter_box .moniter_top>div>ul>li {
    width: 180px;
    height: 65px;
    border-radius: 10px;
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
    /* justify-content: center; */
    align-items: center;
    background: #999;
}

.moniter_wrap .moniter_box .moniter_top>div>ul>li:first-child {
    background:
        #106FD6;
    margin-right: 10px;

}

.moniter_wrap .moniter_box .moniter_top span {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
}

.moniter_wrap .moniter_box .moniter_top button {
    background: #004EA2;
    padding: 5px 10px;
    color: #fff;
}

.moniter_wrap .moniter_box .moniter_middle {
    width: 100%;
    height: auto;
    /* background-color: #fff; */

}

.moniter_item_wrap {
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px;
    width: 100%;

}

.moniter_item_wrap h3 {
    font-size: 18px;
}

.moniter_item_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    /* ; */
}

.moniter_item_top ul {
    display: flex;
}