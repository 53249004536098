.modal_div {
    position: absolute;
    width: 460px;
    height: 347px;
    padding: 20px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 990;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.modal_title {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 20px;
}

.modal_contents {
    width: 400px;
    text-align: center;
    margin: auto;
}

.modal_contents>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}

.modal_contents>div>div {
    font-size: 12px;
    color: #666;
    line-height: 17px;
}

.modal_contents>div>select {
    width: 300px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    align-items: center;
    height: 38px;
    gap: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 5px;
    outline: none;
}

.modal_contents>div>.list_area {
    position: relative;
    width: 300px;
    font-size: 14px;
    color: black;
    height: 38px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    padding: 5px 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal_contents>div>.list_area>input {
    width: 240px;
    font-size: 14px;
    color: black;
    outline: none;
    border: 0;
}

.modal_contents>div>.list_area ul {
    position: absolute;
    min-height: 30px;
    max-height: 150px;
    overflow: hidden;
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    width: 100%;
    left: 0;
    top: 36px;
    padding: 0;
    z-index: 30;
}

.modal_contents>div>.list_area ul li {
    padding-left: 15px;
    padding-right: 15px;
    border-bottom: 1px solid #e0e0e0;
    cursor: pointer;
    list-style-type: none;
}

.modal_contents>div>.list_area ul li h2 {
    font-size: 13px;
    line-height: 15px;
    text-align: left;
    margin: 10.7px auto
}

.modal_btn_area {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #ccc;
    text-align: center;
}

.modal_btn_area>button {
    font-size: 15px;
    cursor: pointer;
    border: 0;
    margin: 0 10px;
}

.modal_btn_area>.submit_btn {
    width: 70px;
    height: 40px;
    background-color: #004EA2;
    color: #fff;
}

.modal_btn_area>.cancel_btn {
    width: 70px;
    height: 40px;
    background-color: #eee;
}

/** 업무상세 모달창 스타일 */

.modal_div2 {
    position: absolute;
    width: 360px;
    min-height: 607px;
    padding: 20px;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 990;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.modal_subtitle {
    font-size: 15px;
    justify-content: flex-start !important;
    font-weight: bold;
    margin-bottom: 12px !important;
}

.modal_subdiv {
    justify-content: flex-start !important;
    margin-bottom: 5px !important;
}

.modal_subdiv>div:first-child {
    width: 55px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
    color: rgb(155, 155, 155);
}

.modal_subdiv>div:last-child {
    font-size: 14px;
    font-weight: 600;
}

.modal_btm_line {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
    width: 300px !important;
}

.modal_chkbox {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.circle_box {
    width: 20px;
    height: 20px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 50%;
    border: 2px solid #d0d5dd !important;
    position: relative;
    transition: all 0.3s;
    margin-right: 5px;
}

.circle_box:checked {
    background-color: #1c1fca;
    border: 1px solid #1c1fca !important;
}

.circle_box:checked::before {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border: 3px solid white;
    border-radius: 50%;
    background-color: transparent;
    transform: translate(-50%, -50%);
}

.modal_txtarea {
    width: 100%;
    height: 105px;
    padding: 5px;
    border: 1px solid rgb(155, 155, 155);
    border-radius: 5px;
    font-size: 13px;
    resize: none;
}

.modal_txtarea:focus {
    outline: none;
}